import React, { useRef, useEffect } from "react"
import lightlogo from "../images/lightlogo.png"
import astro from "../images/rocket.png"
import rocketfire from "../images/fire.png"
import "./astro.css"
const StarField = () => {
  const canvasRef = useRef(null)
  let w
  let h
  let stars = []

  const setCanvasExtents = () => {
    w = document.body.clientWidth
    h = document.body.clientHeight
    canvasRef.current.width = w
    canvasRef.current.height = h
  }

  const makeStars = count => {
    const out = []
    for (let i = 0; i < count; i++) {
      const s = {
        x: Math.random() * 1600 - 800,
        y: Math.random() * 900 - 450,
        z: Math.random() * 1000,
      }
      out.push(s)
    }
    return out
  }

  const clear = c => {
    c.fillStyle = "black"
    c.fillRect(0, 0, w, h)
  }

  const putPixel = (c, x, y, brightness) => {
    const intensity = brightness * 255
    const rgb = `rgb(${intensity},${intensity},${intensity})`
    c.fillStyle = rgb
    c.fillRect(x, y, 1, 1)
  }

  const moveStars = distance => {
    const count = stars.length
    for (let i = 0; i < count; i++) {
      const s = stars[i]
      s.z -= distance
      while (s.z <= 1) {
        s.z += 1000
      }
    }
  }

  const tick = time => {
    let elapsed = time - prevTime
    prevTime = time

    moveStars(elapsed * 0.1)

    const context = canvasRef.current.getContext("2d")
    clear(context)

    const cx = w / 2
    const cy = h / 2

    const count = stars.length
    for (let i = 0; i < count; i++) {
      const star = stars[i]

      const x = cx + star.x / (star.z * 0.001)
      const y = cy + star.y / (star.z * 0.001)

      if (x < 0 || x >= w || y < 0 || y >= h) {
        continue
      }

      const d = star.z / 1000.0
      const b = 1 - d * d

      putPixel(context, x, y, b)
    }

    requestAnimationFrame(tick)
  }

  let prevTime

  useEffect(() => {
    const canvas = canvasRef.current
    setCanvasExtents()
    stars = makeStars(10000)
    prevTime = performance.now()
    requestAnimationFrame(tick)

    const handleResize = () => {
      setCanvasExtents()
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className=" h-screen w-screen bg-black relative  justify-center flex flex-col">
      <div className=" absolute  max-w-lg left-40 flex flex-col items-center space-y-10">
        <img className=" h-16 object-contain" src={lightlogo} alt="Taut" />
        <h1 className=" text-7xl  text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 font-black text-center">
          Websites, Web Apps, & E-commerce
        </h1>
      </div>
      <div className="   absolute  max-w-lg right-40 flex flex-col items-center space-y-10">
        <RocketObject />
      </div>
      <canvas ref={canvasRef} className=" h-full w-full"></canvas>
    </div>
  )
}

export default StarField

const RocketObject = () => {
  return (
    <div className="  relative  max-w-lg  flex flex-col  w-72 h-72 items-center space-y-10">
      <img
        className="astronaut object-contain absolute top-0 z-20 -right-2"
        src={astro}
        alt="Taut"
      />
      <img
        className="rocketfire  animate-pulse object-contain scale-80 absolute z-10 -bottom-44  right-40"
        src={rocketfire}
        alt="Taut"
      />
    </div>
  )
}
